import React, { Component } from "react"
import Layout from "../../components/layout"
import HeaderBanner from "../../components/common/headerBanner/headerBanner"
import CTABanner from "../../components/common/ctaBanner/ctaBanner"
import * as styles from "../support.module.scss"
import phone from "../../../assets/support-phone.png"
import email from "../../../assets/support-email.png"
import chat from "../../../assets/support-chat.png"
import ThreeColumnGrid from "../../components/common/threeColumnGrid/threeColumnGrid"
import Breadcrumb from "../../components/common/breadcrumb/breadcrumb"
import { columnItems } from "../../data/support-items"
import { LearnMoreTagline } from "../../components/common/typography/banner-tagline"
import { TM } from "../../components/common/typography/trademark"

class IronCAPXSupport extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ie: false,
    }
  }
  componentDidMount() {
    if (typeof window !== `undefined`) {
      let isIE = /*@cc_on!@*/ false || !!document.documentMode
      this.setState({
        ie: isIE,
      })
    }
  }
  openChat = () => {
    window.open(
      "http://www.imoncall.com/ironcapchat",
      <>
        IronCAP
        <TM /> Live Chat
      </>,
      "width=500,height=600"
    )
  }
  render() {
    return (
      <Layout>
        <HeaderBanner title="Support" />

        <div className={styles.pageContainer}>
          <Breadcrumb
            firstLink="/ironcap-x"
            firstLinkTitle="IronCAP X"
            secondLink="/ironcap-x/support"
            secondLinkTitle="Support"
          />
          <div className={styles.pageText}>
            <p>
              Contact our knowledgeable staff anytime for immediate assistance.
              Whether you need technical support while operating one of our
              programs or have questions regarding pricing or features of our
              product offering, we are available at your convenience. For
              pre-sales support, our product specialists will recommend you the
              appropriate solutions based on your industry or personal needs.
              Call us to find out how to transform your business or your product
              offerings to be Quantum-Safe.
            </p>
          </div>

          <div className={styles.contactGrid}>
            <div className={this.state.ie ? styles.contactGridItemIE : null}>
              <img src={email} alt="Email" />
              <div className={styles.contactText}>
                <p>
                  <a href="mailto:info@ironcap.ca" target="_blank">
                    info@ironcap.ca
                  </a>
                </p>
              </div>
            </div>
            <div className={this.state.ie ? styles.contactGridItemIE : null}>
              <img src={phone} alt="Phone" />
              <div className={styles.contactText}>
                <p>1-(800)-668-2185</p>
              </div>
            </div>
            <a
              target="_blank"
              className={this.state.ie ? styles.contactGridItemIE : null}
              onClick={this.openChat}
            >
              <div>
                <img src={chat} alt="Pre-sales chat" />
                <div className={styles.contactText}>
                  <div className={styles.chatText}>
                    <p>Live Chat</p>
                    <p>Pre-Sales</p>
                  </div>
                </div>
              </div>
            </a>
            <a
              target="_blank"
              className={this.state.ie ? styles.contactGridItemIE : null}
              onClick={this.openChat}
            >
              <div>
                <img src={chat} alt="After-sales chat" />
                <div className={styles.contactText}>
                  <div className={styles.chatText}>
                    <p>Live Chat</p>
                    <p>After-Sales</p>
                  </div>
                </div>
              </div>
            </a>
          </div>

          <ThreeColumnGrid columnItems={columnItems} IE={this.state.ie} />
          <CTABanner
            tagline={LearnMoreTagline}
            CTA="video"
            CTADetail="how video"
          />
        </div>
      </Layout>
    )
  }
}
export default IronCAPXSupport
